import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'

const workOrderServices = {
  /**
   * 工单统计
  */
  async workOrderStatistics(params) {
    return await request.post(`${SERVER.QUESTION_SERVICE}/api/v1/workOrder/statistics`, params).then(thenResolve)
  },
  /**
   * 工单分页查询
  */
  async workOrderPageList(params) {
    return await request.post(`${SERVER.QUESTION_SERVICE}/api/v1/workOrder/page`, params).then(thenResolve)
  },
  /**
   * 获取工单详情
  */
  async workOrderDetail(params) {
    return await request.get(`${SERVER.QUESTION_SERVICE}/api/v1/workOrder/detail`, { params }).then(thenResolve)
  },
  /**
   * 工单跟进
  */
  async workOrderFollow(params) {
    return await request.post(`${SERVER.QUESTION_SERVICE}/api/v1/workOrder/follow`, params).then(thenResolve)
  },
}
export default workOrderServices
