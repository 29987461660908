<template>
  <div class="workOrderItem common-block" @click="$emit('goDetail', itemData.id)">
    <div class="row orderId">{{ $t('单号：') }}{{ itemData.questionId }}</div>
    <div class="row type">
      <span class="name">{{ itemData.customerName }}</span>
      <span class="typeName">{{ itemData.eventType | eventTypeFilter }}</span>
    </div>
    <div class="row phone">
      <span class="label">手机号：</span>
      <span class="content">{{ itemData.customerMobile }}</span>
    </div>
    <div class="row follow">
      <div class="label">{{ $t('跟进人：') }}</div>
      <span class="content">{{ itemData.followUserName }}</span>
    </div>
    <div class="row follow">
      <div class="label">{{ $t('门店名称：') }}</div>
      <span class="content">{{ itemData.dealerName }}</span>
    </div>
    <div class="row status">{{ itemData.status === 0 ? $t('未解决') : itemData.status === 1 ? $t('待跟进') : itemData.status === 2 ? $t('待回访') : $t('已解决') }}</div>
  </div>
</template>
<script>
export default {
  filters: {
    eventTypeFilter(val) {
      const obj = {
        'TEST_DRIVE_SATISFACTION': '试驾低满',
        'SERVICE_SATISFACTION': '购车低满',
        'PICK_UP_CAR_SATISFACTION': '交付低满',
      }
      return val ? obj[val] || '' : ''
    }
  },
  props: {
    itemData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>
<style lang="less" scoped>
  .workOrderItem{
    padding: 16px 12px 0px;
    display: flex;
    flex-direction: column;
    .row{
      display: flex;
      margin-bottom: 8px;
      font-size: 13px;
      align-items: center;
      .label{
        color: rgba(13, 23, 26, 0.45);
        max-width: 65px;
      }
      .content{
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.orderId{
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(13, 23, 26, 0.05);
      }
      &.type{
        font-size: 16px;
        justify-content: space-between;
        .name{
          flex: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .typeName{
          font-size: 13px;
          color: #FF7D00;
          min-width: 55px;
          max-width: 65px;
        }
      }
      &.status{
        border-top: 1px solid rgba(13, 23, 26, 0.05);
        justify-content: flex-end;
        display: flex;
        padding-top: 8px;
      }
    }
  }
</style>