<template>
  <div class="lowFullWorkOrder">
    <div class="lowFullWorkOrder-l">
      <div
        v-for="(item, index) in tabs"
        :key="index"
        :class="{ current: searchParams.eventType === item.eventType }"
        @click="changeTab(item)">
        <p>{{ item.eventTypeName }}</p>
        <p>{{ `(${item.count})` }}</p>
      </div>
    </div>
    <div v-if="searchParams.eventType" class="lowFullWorkOrder-r">
      <div class="search-wrap">
        <van-tabs
          v-model="searchParams.status"
          @change="onChangeQueryType"
        >
          <van-tab :title="$t('待跟进')" :name="1"></van-tab>
          <van-tab :title="$t('待回访')" :name="2"></van-tab>
          <van-tab :title="$t('已解决')" :name="3"></van-tab>
        </van-tabs>
      </div>
      <div class="dataContent">
        <List ref="list" :fetchApi="fetchApi">
          <template v-slot:default="{ item, index }">
            <Item :item-data="item" @goDetail="goDetail"></Item>
          </template>
        </List>
      </div>
    </div>
  </div>
</template>
<script>
import List from '@/components/baseList/list.vue'
import loading from '@/utils/loading'
import Item from './components/Item.vue'
import workOrderServices from '@/services/lowFullWorkOrder'
export default {
  name: 'LowFullWorkOrder',
  components: {
    List,
    Item
  },
  data() {
    return {
      searchParams: {
        eventType: '',
        status: 1
      },
      tabs: []
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'LowFullWorkOrder')
    })
  },
  computed: {
  },
  watch: {
    tabs: {
      handler(val) {
        if (val && val.length > 0) {
          this.searchParams.eventType = val[0].eventType
        }
      },
      immediate: true,
      deep: true
    },
    'searchParams.eventType': {
      handler(val) {
        if (val) {
          this.$refs.list?.onRefresh()
        }
      }
    },
    'searchParams.status': {
      handler(val) {
        if (val) {
          this.$refs.list?.onRefresh()
        }
      }
    },
  },
  activated() {
    this.getStatistice()
    this.$refs.list?.onRefresh()
  },
  mounted() {
    this.getStatistice()
  },
  methods: {
    // 获取统计数据
    async getStatistice() {
      loading.showLoading()
      const res = await workOrderServices.workOrderStatistics()
      loading.hideLoading()
      this.tabs = res
    },
    async fetchApi(obj={}){
      const { eventType, status } = this.searchParams
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        eventType,
        status
      }
      loading.showLoading()
      const res = await workOrderServices.workOrderPageList(params)
      loading.hideLoading()
      return res
    },
    // 切换工单类型
    changeTab(item) {
      this.searchParams.eventType = item.eventType
    },
    // 切换状态
    onChangeQueryType(data) {
      this.searchParams.status = data
    },
    // 详情
    goDetail(id) {
      this.$router.push({
        path: '/workOrderDetail',
        query: {
          id
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.lowFullWorkOrder{
  display: flex;
  height: 10vh;
  .lowFullWorkOrder-l {
    overflow-y: auto;
    font-size: 14px;
    text-align: center;
    > div {
      cursor: pointer;
      padding: 10px 16px;
      max-width: 4em;
      box-sizing: content-box;
      &.current {
        color: #b9921a;
      }
    }
  }
  .lowFullWorkOrder-r{
    position: relative;
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    .dataContent{
      flex: 1;
      overflow-y: scroll;
    }
  }
}
</style>